.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 1rem 0;
}

.center::after {
  background: var(--primary-glow);
  width: 240px;
  height: 180px;
  z-index: -1;
}

.center::before,
.center::after {
  content: '';
  left: 50%;
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

.logo {
  position: relative;
  width: 450px;
}

/* Mobile */
@media (max-width: 700px) {
  .center {
    padding: 1rem 0 6rem;
  }

  .center::before {
    transform: none;
    height: 300px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* EthanolCalculator */

.ethanol-calculator {
  display: flex;
  flex-direction: column;
}

.ethanol-calculator > * {
  margin-bottom: 1rem;
}

.calculator-result {
  display: flex;
}

.calculator-result > .fuel, .calculator-result > .ethanol {
  background: #f3f3f3;
  width: 100px;
  text-align: center;
  border: 1px solid #9f9f9f;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
}

.calculator-result > .fuel {
  margin-right: 5px;
}

.calculator-result .value {
  font-size: 2rem;
}

.ethanol-blend, .tank-capacity > input {
  padding: .5rem;
  background: #fff;
  border: 0;
  border-radius: 4px;
}

.tank-capacity {
  display: flex;
  flex-direction: column;
}
